export const hardSkills = [
  "MERN Stack",
  "React.js",
  "Node.js",
  "TypeScript",
  "Kotlin",
  "Python",
  "MySQL",
  "MongoDB",
  "Express",
  "React Hooks",
  "Context API",
  "Redux",
  "ES6",
  "HTML5",
  "Sass/Scss",
  "AWS",
];
